import React, { useEffect, useState, useContext } from 'react';
import ProgressBar from './progress-bar';
import PricingBox from './pricing-box';
import API from './../../api/api';
import yfLogo from './../../assets/image/png/yf-logo.png';
import trustpilot from './../../assets/image/jpeg/trustpilot.jpg';
import Seo from "./../../components/Seo";
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Button } from '../../components/Core';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { getAssociativeArray, getProperty } from "../../utils/helperFn";
import GlobalContext from "../../context/GlobalContext";
import downArrow from './../../assets/image/png/down-arrow.png'
import { Modal } from 'react-bootstrap'
import { navigate, graphql, useStaticQuery } from "gatsby"
import ResidentialForm from '../../components/Forms/ResidentialForm/ResidentialForm';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Success = (props) => {
    const [mainProducts, setMainProducts] = useState([]);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [showHomepageButton, setShowHomepageButton] = useState(false)
    const gContext = useContext(GlobalContext)

    const GradientSection = styled(Section)`
      background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
      margin-top: 100px;
      padding-bottom: 15px;
    `;

    const data = useStaticQuery(graphql`
      query getSuccessGenericRegisterPageData {
          allStrapiOrderJourney {
              nodes {
                  sales_status
                  title
                  subtitle_1
              }
          }
      }`
    );

    const staticText = getAssociativeArray(data.allStrapiOrderJourney.nodes, 'sales_status');

    const closeModal = () => {
      setIsSuccessModalOpen(false)
      setShowHomepageButton(true)
  }

    const renderRegistrationTitle = (pageText) => {
      return <Title className="mt-3 mb-4 text-center">{getProperty(pageText, 'title')}</Title>;
    }

    const renderRegistrationSubtitle = (pageText) => {

      return <Row>
          <Col sm={12} md={9} className="mx-auto mb-4">
              <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_1')}</Text>
              {getProperty(pageText, 'subtitle_2') && <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_2')}</Text>}
          </Col>
      </Row>
    }

    const renderRegistrationStep = () => {
      const registrationData = gContext.goGetRegistrationData();
      const noStatus = registrationData?.SearchPremiseResults?.properties?.NetomniaSalesStatus;
    
      // Not available
      if (noStatus === 'RI_GENERIC' || !noStatus) {
        return 0;
      }
    
      // Planning
      const step1Statuses = ['RI_PHASE3', 'RI_INPLANNING', 'RI_INPLANNING_EX_INBUILD', 'RI_ONHOLD', 'RI_ONHOLD_EX_INBUILD'];
      if (step1Statuses.includes(noStatus)) {
        return 1;
      }
    
      // In Build
      const step2Statuses = ['RI_LANDLORD', 'PRE_ORDER'];
      if (step2Statuses.includes(noStatus)) {
        return 2;
      }
    
      // Ready for service
      if (noStatus === 'ORDER') {
        return 3;
      }
    };

    // Define text status.
    const textStatus = gContext.goGetRegistrationData()?.SearchPremiseResults ? getProperty(gContext.goGetRegistrationData()?.SearchPremiseResults.properties, 'NetomniaSalesStatus') : undefined

    // Define texts for page with specific sales status or null/undefined.
    const pageText = staticText[textStatus ? textStatus : 'default'];

    useEffect(() => {
      API
            /* 1. Get the offer passed from the Pricing section */
            .get(`/ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true`)
            .then(response => {
                const products = response?.data?.data?.Product?.dbRecords;
                if (products) {
                    let mainProducts = products.filter(
                      product => product.properties.Type === 'BASE_PRODUCT' && product.properties.ContractType === 'ANNUAL_18'
                  );
                    setMainProducts(mainProducts.sort((a, b) => parseInt(a.properties.Description) - parseInt(b.properties.Description)));
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });
    }, []);

    const onSuccess = () => {
      setIsSuccessModalOpen(true)
    }

    const handleArrowClick = () => {
      scrollTo('#registration-form')
    }

    return (
      <>
        <Seo page="index" />
        <PageWrapper>
          <GradientSection>
            <Container>
              <Text className="text-center mb-5 white-color">
                {gContext.goGetRegistrationData()?.SearchPremiseResults?.title}
              </Text>
              <ProgressBar
                selectedStep={renderRegistrationStep() === 3 ? 4 : renderRegistrationStep()}
                title={renderRegistrationTitle(pageText)}
                subtitle={renderRegistrationSubtitle(pageText)}
              />
              {
                renderRegistrationStep() === 3 || (props?.location?.state?.pageData?.riGeneric && !showHomepageButton) ?
                <img
                  src={downArrow}
                  width={70}
                  className='m-auto d-block pt-3'
                  onClick={() => handleArrowClick()}
                /> : <div className='mb-5 pb-5'></div>
              }
            </Container>
          </GradientSection>
          {
            renderRegistrationStep() === 3 &&
            <Section>
              <Container>
                <Title variant="card" mb="12px" className="text-center">
                  Available partners
                </Title>
                <Text className="text-center mb-5">
                  Now our network is available in your area, you'll be able to choose from a range of offers from
                  our broadband providers. With over 30 to choose from - you can always find the best deal.
                </Text>
                {mainProducts.map(product => (
                  <PricingBox
                    logo={yfLogo}
                    trustpilot={trustpilot}
                    product={product.properties}
                    planName={product.properties.DisplayName}
                    speed={product.properties.Description}
                    rating={5}
                  />  
                ))}
              </Container>
            </Section>
          }
          {
            props?.location?.state?.pageData?.riGeneric && !showHomepageButton &&
            <div className="mb-5" id="registration-form">
              <Row className="justify-content-center">
                <Col lg={6} md={8} sm={12} className="text-center mt-5 pt-5">
                  <div
                    className="text-center provider-text"
                    dangerouslySetInnerHTML={{
                      __html: `<h4 className="color-black">Please enter your details</h4>`,
                    }}
                  />
                </Col>
              </Row>
              <ResidentialForm successPage={true} onSuccess={onSuccess}/>
            </div>
          }
          {
            showHomepageButton && props?.location?.state?.pageData?.riGeneric &&
            <Button
              style={{
                  background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                  border: 'none',
                  width: 'auto',
                  margin: '50px auto',
                  display: 'block'
              }}
              className="btn-red"
              onClick={() => navigate('/')}
            >
              Return to homepage
            </Button>
          }
        </PageWrapper>
        <Modal centered show={isSuccessModalOpen} onHide={closeModal}>
          <Modal.Header>
              <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={`alert alert-success`}>
              Thank you for registering your interest.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
      </Modal>
      </>
    );
};
export default Success;
